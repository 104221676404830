<template>
  <div
    id="app-content"
    class="d-flex flex-column"
  >
    <server />
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <div
        id="app-content-padding"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import AppServer from '@core/components/app-server/AppServer.vue'

export default {
  components: {
    server: AppServer,
  },
  computed: {
    routerTransition() {
      return 'fade'
    },
  },
}
</script>

<style lang="scss" scoped>
#app-content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
}

#app-content-padding {
  //padding: calc(env(safe-area-inset-top) / 2 + 25px) 25px calc(env(safe-area-inset-top)/3 + 25px) 25px;
  padding: calc(env(safe-area-inset-top) + 15px) 25px 25px 25px;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
</style>
