<template>
  <layout-blank>
    <router-view />
  </layout-blank>
</template>

<script>
import LayoutBlank from '@core/layouts/layout-blank/LayoutBlank.vue'

export default {
  components: {
    LayoutBlank,
  },
}
</script>
